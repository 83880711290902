import React from 'react';
import classes from "./cart.module.sass";
import Button from "../button/button";
import Hr from "../hr/hr";

const Cart = ({variant, del, variantBack, variantDel, buttonDelText, to, next, back, type, buttonText, buttonBackText, children}) => {
    switch (type){
        case 'button':
            return (
                <div className={classes.cart}>
                    {children}
                    <Hr/>
                    <Button to={to} variant={variant}>{buttonText}</Button>
                </div>
            );
        case 'add':
            return (
                <div className={classes.cart}>
                    {children}
                    <Hr/>
                    {
                        next && <Button to={to} onClick={next} variant={variant} style={{marginBottom: '16px'}}>{buttonText}</Button>
                    }
                    <Button to={to} onClick={back} variant={variantBack}>{buttonBackText}</Button>
                </div>
            );
        case 'delete':
            return (
                <div className={classes.cart}>
                    {children}
                    <Hr/>
                    <Button to={to} onClick={next} variant={variant} style={{marginBottom: '16px'}}>{buttonText}</Button>
                    <Button to={to} onClick={back} variant={variantBack} style={{marginBottom: '16px'}}>{buttonBackText}</Button>
                    <Button to={to} onClick={del} variant={variantDel}>{buttonDelText}</Button>
                </div>
            );
        default:
            return (
                <div className={classes.cart}>
                    {children}
                </div>
            );
    }
};

export default Cart;