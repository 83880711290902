import React from 'react';
import ErrorComponent from "../components/errorComponent";
import App from "../App";

const ErrorPage = () => {
    return (
        <>
            <App/>
            <ErrorComponent />
        </>
    );
};

export default ErrorPage;