import {$authHost} from "../http/axios";

export default class CameraService{
    static getCameras = async () => {
        const {data} = await $authHost.get('/camera');
        return data;
    }

    static getCamera = async (id) => {
        const {data} = await $authHost.get(`/camera/${id}`);
        return data;
    }

    static getCameraByUrl = async (url) => {
        const {data} = await $authHost.get(`/camera/get/${url}`);
        return data;
    }

    static addCamera = async (name, rele, movement, url, accuracy, locationId) => {
        const {data} = await $authHost.post('/camera', {name, rele, movement, url, accuracy, locationId});
        return data;
    }

    static editCamera = async (id, name, rele, movement, url, accuracy, locationId) => {
        const {data} = await $authHost.put(`/camera/${id}`, {name, rele, movement, url, accuracy, locationId});
        return data;
    }

    static removeCamera = async (id) => {
        const {data} = await $authHost.delete(`/camera/${id}`);
        return data;
    }
}