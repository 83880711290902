import React from 'react';
import Container from "../ui/container/container";
import Cart from "../ui/cart/cart";
import BottomMenu from "../ui/menu/bottomMenu";
import Button from "../ui/button/button";
import {observer} from "mobx-react-lite";

const ErrorComponent = observer(() => {
    return (
        <div>
            <Container>
                <div className="flex jc-center">
                    <Cart>
                        <h2>404 ¯\_(ツ)_/¯ <br/><br/>Упс, но такой страницы не существует</h2>
                        <Button variant={'link'} to={'/'}>Перейти на главную</Button>
                    </Cart>
                </div>
            </Container>
            <BottomMenu>
                <div className="count"></div>
                <div className="addButton"></div>
            </BottomMenu>
        </div>
    );
});

export default ErrorComponent;