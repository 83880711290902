import React from 'react';
import {Context} from "../index";
import CategoryService from "../services/categoryService";
import Loader from "../ui/loader/loader";
import Container from "../ui/container/container";
import Cart from "../ui/cart/cart";
import BottomMenu from "../ui/menu/bottomMenu";
import Bar from "../ui/bar/bar";
import Button from "../ui/button/button";
import {useLocation} from "react-router-dom";
import {adminSettingMenu} from "../routes/menu";
import {observer} from "mobx-react-lite";
import Moment from "react-moment";

const CategoryComponent = observer(() => {
    const {category} = React.useContext(Context);
    const location = useLocation();

    const [error, setError] = React.useState('');
    const [loader, setLoader] = React.useState(true);

    React.useEffect(() => {
        CategoryService.getCategories()
            .then(data => {
                category.setCategories(data);
            })
            .catch(err => {
                setError(err.response.data.message);
            })
            .finally(() => {
                setLoader(false);
            })
    }, [category]);

    if (loader){
        return <Loader/>
    }

    return (
        <div>
            {
                error && <p>{error}</p>
            }
            <Bar>
                <ul>
                    {
                        adminSettingMenu.map(
                            item => <li key={item.id} className={location.pathname === item.url && 'active'}>
                                <Button to={item.url} variant={'linkText'}>{item.title}</Button>
                            </li>
                        )
                    }
                </ul>
            </Bar>
            <Container
                variant={'leftBar'}
            >
                <div className="flex fw-wrap">
                    {
                        category.isCategories.rows.map(
                            item =>
                                <Cart
                                    key={item.id}
                                >
                                    <div className="name">
                                        <span>Имя:</span>
                                        <p>{item.name}</p>
                                    </div>
                                    <div className="role">
                                        <span>Добавлен(а):</span>
                                        <p><Moment format={'YYYY.MM.DD HH:mm:ss'}>{item.createdAt}</Moment></p>
                                    </div>
                                </Cart>
                        )
                    }
                </div>
            </Container>
            <BottomMenu>
                <div className="count">
                    <span>Всего: </span><p>{category.isCategories.count}</p>
                </div>
                <div className="addButton"></div>
            </BottomMenu>
        </div>
    );
});

export default CategoryComponent;