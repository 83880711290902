import {$authHost} from "../http/axios";

export default class CategoryService{
    static getCategories = async () => {
        const {data} = await $authHost.get('/category');
        return data;
    }

    static getCategory = async (id) => {
        const {data} = await $authHost.get(`/category/${id}`);
        return data;
    }

    static addCategory = async (name) => {
        const {data} = await $authHost.post('/category', {name});
        return data;
    }

    static editCategory = async (id, name) => {
        const {data} = await $authHost.put(`/camera/${id}`, {name});
        return data;
    }

    static removeCategory = async (id) => {
        const {data} = await $authHost.delete(`/category/${id}`);
        return data;
    }
}