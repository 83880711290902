import {$host, $authHost} from "../http/axios";
import jwt_decode from 'jwt-decode';

export default class UserService {
    static login = async (email, password) => {
        const {data} = await $host.post('/user/login', {email, password});
        localStorage.setItem('token', data.token)
        return jwt_decode(data.token)
    }

    static check = async () => {
        const {data} = await $authHost.get('/user/auth');
        localStorage.setItem('token', data.token)
        return jwt_decode(data.token)
    }

    static getUsers = async () => {
        const {data} = await $authHost.get('/user');
        return data
    }

    static getUser = async (id) => {
        const {data} = await $authHost.get(`/user/${id}`);
        return data
    }

    static addUser = async (name, email, roleId, companyId) => {
        const {data} = await $authHost.post('/user/registration', {name, email, roleId, companyId})
        return data
    }

    static editUser = async (id, name, email, phone, roleId, companyId) => {
        const {data} = await $authHost.put(`/user/${id}`, {name, email, phone, roleId, companyId})
        return data
    }

    static removeUser = async (id) => {
        const {data} = await $authHost.delete(`/user/${id}`)
        return data
    }
}