import {makeAutoObservable} from "mobx";

export default class RoleStore {
    constructor() {
        this._role = {}
        this._roles = []
        makeAutoObservable(this);
    }

    setRole(role) {
        this._role = role
    }

    setRoles(roles) {
        this._roles = roles
    }

    get isRole(){
        return this._role
    }

    get isRoles(){
        return this._roles
    }
}