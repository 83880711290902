import {makeAutoObservable} from "mobx";

export default class LocationStore {
    constructor() {
        this._location = {}
        this._locations = []
        makeAutoObservable(this);
    }

    setLocation(location) {
        this._location = location
    }

    setLocations(locations) {
        this._locations = locations
    }

    get isLocation(){
        return this._location
    }

    get isLocations(){
        return this._locations
    }
}