import React from 'react';
import PeopleOneComponent from "../components/peopleOneComponent";
import App from "../App";

const PeopleOnePage = () => {
    return (
        <>
            <App/>
            <PeopleOneComponent />
        </>
    );
};

export default PeopleOnePage;