import {$authHost} from "../http/axios";

export default class RoleService{
    static getRoles = async () => {
        const {data} = await $authHost.get('/role');
        return data;
    }

    static getRole = async (id) => {
        const {data} = await $authHost.get(`/role/${id}`);
        return data;
    }

    static addRole = async (name) => {
        const {data} = await $authHost.post('/role', {name});
        return data;
    }

    static editRole = async (id, name) => {
        const {data} = await $authHost.put(`/role/${id}`, {name});
        return data;
    }

    static removeRole = async (id) => {
        const {data} = await $authHost.delete(`/role/${id}`);
        return data;
    }
}