import React from 'react';
import UserService from "../services/userService";
import {useParams, useNavigate} from "react-router-dom";
import Loader from "../ui/loader/loader";
import Container from "../ui/container/container";
import Cart from "../ui/cart/cart";
import {Context} from "../index";
import BottomMenu from "../ui/menu/bottomMenu";
import Input from "../ui/input/input";
import Popup from "../ui/popup/popup";
import Hr from "../ui/hr/hr";
import Button from "../ui/button/button";
import Select from "../ui/select/select";
import CompanyService from "../services/companyService";
import RoleService from "../services/roleService";
import {observer} from "mobx-react-lite";

const UserOneComponent = observer(() => {
    const {id} = useParams();
    const {user, company, role} = React.useContext(Context);
    const navigate = useNavigate();
    const [edit, setEdit] = React.useState(false);
    const [popup, setPopup] = React.useState(false);
    const [name, setName] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [roleId, setRoleId] = React.useState('');
    const [companyId, setCompanyId] = React.useState('');

    const [error, setError] = React.useState('');
    const [loader, setLoader] = React.useState(true);

    const loadData = async (id) => {
        const [userResult, companyResult, roleResult] = await Promise.allSettled([
            UserService.getUser(id),
            CompanyService.getCompanies(),
            RoleService.getRoles()
        ])
        user.setUser(userResult.value);
        company.setCompanies(companyResult.value);
        role.setRoles(roleResult.value);
    }

    React.useEffect(() => {
        loadData(id)
            .then()
            .catch(err => setError(err.response.data.message))
            .finally(() => setLoader(false))
    }, [id])

    const userEdit = () => {
        setEdit(true);
        setName(user.isUser?.name);
        setPhone(user.isUser?.phone);
        setRoleId(user.isUser?.roleId);
        setCompanyId(user.isUser?.companyId);
    }

    const canselEdit = () => {
        setEdit(false);
        setName('');
        setPhone('');
        setRoleId('');
        setCompanyId('');
    }

    const saveEdit = () => {
        setLoader(true);
        UserService.editUser(id, name, user.isUser?.email, phone, roleId, companyId)
            .then(() => navigate('/users'))
            .catch(err => setError(err.response.data.message))
    }

    const openPopup = () => {
        setPopup(true);
    }

    const closePopup = () => {
        setPopup(false);
    }

    const deleteUser = () => {
        setLoader(true);
        UserService.removeUser(id)
            .then(() => navigate('/users'))
            .catch(err => setError(err.response.data.message))
            .finally(() => setLoader(false))
    }

    if (loader){
        return <Loader/>
    }

    return (
        <div>
            {
                error && <p>{error}</p>
            }
            <Container>
                <div className="flex jc-center">
                    {
                        !edit
                            ?
                            <Cart
                                variant={'link'}
                                variantBack={'outline'}
                                type={'add'}
                                buttonText={'Изменить'}
                                buttonBackText={'Назад'}
                                next={userEdit}
                                back={() => navigate(-1)}
                            >
                                <div className="name">
                                    <span>Имя:</span>
                                    <p>{user.isUser?.name}</p>
                                </div>
                                <div className="email">
                                    <span>Email:</span>
                                    <p>{user.isUser?.email}</p>
                                </div>
                                <div className="phone">
                                    <span>Телефон:</span>
                                    <p>{user.isUser?.phone}</p>
                                </div>
                                <div className="company">
                                    <span>Компания:</span>
                                    <p>{user.isUser?.company.name}</p>
                                </div>
                                <div className="role">
                                    <span>Роль:</span>
                                    <p>{user.isUser?.role.name}</p>
                                </div>
                            </Cart>
                            :
                            <Cart
                                variant={'link'}
                                variantBack={'outline'}
                                variantDel={'linkText'}
                                type={'delete'}
                                buttonText={'Сохранить'}
                                buttonBackText={'Отмена'}
                                buttonDelText={'Удалить'}
                                next={saveEdit}
                                back={canselEdit}
                                del={openPopup}
                            >
                                <div className="name">
                                    <span>Имя:</span>
                                    <Input value={name} onChange={event => setName(event.target.value)}/>
                                </div>
                                <div className="email">
                                    <span>Email:</span>
                                    <p>{user.isUser?.email}</p>
                                </div>
                                <div className="phone">
                                    <span>Телефон:</span>
                                    <Input value={phone} onChange={event => setPhone(event.target.value)}/>
                                </div>
                                <div className="company">
                                    <span>Компания:</span>
                                    <Select value={companyId} onChange={event => setCompanyId(event.target.value)}>
                                        {
                                            company.isCompanies?.rows.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                                        }
                                    </Select>
                                </div>
                                <div className="role">
                                    <span>Роль:</span>
                                    <Select value={roleId} onChange={event => setRoleId(event.target.value)}>
                                        {
                                            role.isRoles?.rows.map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                                        }
                                    </Select>
                                </div>
                            </Cart>
                    }
                </div>
            </Container>
            <BottomMenu>
                <div className="count"></div>
                <div className="addButton"></div>
            </BottomMenu>
            {
                popup &&
                <Popup>
                    <div style={{marginTop: '72px', flexDirection: 'column'}} className='flex jc-center ai-center'>
                        <Cart>
                            <h3 style={{marginBottom: '16px'}}>Вы точно хотите удалить пользователя: {name}?</h3>
                            <Hr/>
                            <Button onClick={deleteUser} style={{width: '100%'}} variant={'linkText'}>Да</Button>
                            <Button onClick={closePopup} style={{width: '100%', marginTop: '16px'}}  variant={'link'}>Отмена</Button>
                        </Cart>
                    </div>
                </Popup>
            }
        </div>
    );
});

export default UserOneComponent;