import {makeAutoObservable} from "mobx";

export default class CategoryStore {
    constructor() {
        this._category = {}
        this._categories = []
        makeAutoObservable(this);
    }

    setCategory(category) {
        this._camera = category
    }

    setCategories(categories) {
        this._categories = categories
    }

    get isCategory(){
        return this._category
    }

    get isCategories(){
        return this._categories
    }
}