import React from 'react';
import classes from './bar.module.sass';

const Bar = ({variant, children}) => {
    switch (variant){
        case 'right':
            return (
                <div className={classes.rightBar}>
                    {children}
                </div>
            );
        default:
            return (
                <div className={classes.leftBar}>
                    {children}
                </div>
            );
    }
};

export default Bar;