import React from 'react';
import {observer} from "mobx-react-lite";
import {useParams} from "react-router-dom";
import CameraService from "../services/cameraService";
import {Context} from "../index";
import Loader from "../ui/loader/loader";
import * as faceapi from "face-api.js";
import Container from "../ui/container/container";
import Input from "../ui/input/input";
import Button from "../ui/button/button";
import PeopleService from "../services/peopleService";
import JournalService from "../services/journalService";
import {v4} from 'uuid';
import CompareService from "../services/compareService";


const CameraStreamOneComponent = observer(() => {
    const {url} = useParams();
    const {camera} = React.useContext(Context);
    const [error, setError] = React.useState('');
    const [loader, setLoader] = React.useState(true);
    const [modelsLoaded, setModelsLoaded] = React.useState(false);
    const [name, setName] = React.useState('');
    const [oldName, setOldName] = React.useState('');
    const [cameraImg, setCameraImg] = React.useState('');
    const [compare, setCompare] = React.useState(false);
    const [captureVideo, setCaptureVideo] = React.useState(false);

    const videoRef = React.useRef(null);
    const canvasRef = React.useRef(null);
    const canvasRefImg = React.useRef(null);
    const videoHeight = 320;
    const videoWidth = 320;

    React.useEffect(() => {
        CameraService.getCameraByUrl(url)
            .then(data => {
                camera.setCamera(data)
            })
            .catch(err => setError(err.response.data.message))
        const loadModels = async () => {
            const MODEL_URL = process.env.PUBLIC_URL + '/models';
            Promise.all([
                faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
                faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
                faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
                faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
            ])
                .then(() => setModelsLoaded(true));
        }
        loadModels().then().finally(() => setLoader(false));
    }, [camera, url]);

    const startVideo = () => {
        const contains = {
            audio: false,
            frameRate: { ideal: 10, max: 25 },
            video: { facingMode: "user", width: 320, height: 320 },
        };
        setCaptureVideo(true);
        navigator.mediaDevices.getUserMedia(contains)
            .then(streamObj => {
                videoRef.current.srcObject = streamObj;
                videoRef.current.onloadedmetadata = function (e) {
                    e.preventDefault();
                    videoRef.current.play();
                };
            })
            .catch(err =>
                setError(err)
            );
    }

    const handleVideoOnPlay = () => {
        const tinyFaceDetector = new faceapi.TinyFaceDetectorOptions();
        setInterval(() => {
            if (canvasRef && canvasRef.current) {
                canvasRef.current.innerHTML = faceapi.createCanvasFromMedia(videoRef.current);
                const displaySize = {
                    width: videoWidth,
                    height: videoHeight
                }
                faceapi.matchDimensions(canvasRef.current, displaySize);
                faceapi.detectSingleFace(videoRef.current, tinyFaceDetector)
                    .then(detection => {
                        if (detection){
                            const resizedDetections = faceapi.resizeResults(detection, displaySize);
                            canvasRef && canvasRef.current && canvasRef.current.getContext('2d').clearRect(0, 0, videoWidth, videoHeight);
                            canvasRef && canvasRef.current && faceapi.draw.drawDetections(canvasRef.current, resizedDetections);
                        }
                    })
                    .catch(err => setError(err.response.data.message));
                faceapi.detectSingleFace(videoRef.current, tinyFaceDetector).withFaceLandmarks().withFaceDescriptor()
                    .then(descriptor => {
                        if (descriptor) {
                            const array = descriptor.descriptor
                            const labeledDescriptors = [
                                new faceapi.LabeledFaceDescriptors(
                                    'unknown',
                                    [array]
                                )
                            ]
                            CompareService.compare(labeledDescriptors, camera.isCamera.accuracy).then(response => {
                                if (response) {
                                    setName(response.message.split(' (')[0])
                                    const contextImg = canvasRefImg.current.getContext('2d')
                                    contextImg.drawImage(videoRef.current, 0, 0, videoWidth, videoHeight);
                                    const base64dataUrl = canvasRefImg.current.toDataURL('image/jpeg');
                                    contextImg.setTransform(1, 0, 0, 1, 0, 0);
                                    setCameraImg(base64dataUrl);
                                    if (response.message.split(' (')[0] === oldName){
                                        setCompare(false)
                                    } else {
                                        setCompare(true)
                                    }
                                }
                            })
                        }
                    })
                    .catch(err => setError(err.response.data.message));
            }
        }, 1000);
    }

    // const closeWebcam = () => {
    //     videoRef.current.pause();
    //     videoRef.current.srcObject.getTracks()[0].stop();
    //     setCaptureVideo(false);
    // }

    // const talk = (text) => {
    //     const synth = window.speechSynthesis;
    //     const utterance = new SpeechSynthesisUtterance(text)
    //     synth.speak(utterance);
    // }

    React.useEffect(() => {
        if (compare){
            const uuidName = v4()
            if (name !== 'Вы мне неизвестны') {
                setOldName(name)
                PeopleService.getPeopleByName(name)
                    .then(data => {
                        JournalService.addJournal(
                            name + '.jpg',
                            uuidName + '.jpg',
                            name,
                            data.company.name,
                            camera.isCamera.movement,
                            cameraImg,
                            camera.isCamera.rele,
                            true
                        ).then()
                        // if (camera.isCamera.movement === 'Вход'){
                        //     talk(`Здравствуйте, ${name}`);
                        // } else {
                        //     talk(`До-свидания, ${name}`);
                        // }
                    })
                    .catch(err => setError(err.response.data.message))
            }

            setTimeout(() => {
                setName('');
            }, 10000);

        }
    }, [name, compare])

    if (loader){
        return <Loader/>
    }

    return (
        <div>
            {/*{*/}
            {/*    error && <p>{error}</p>*/}
            {/*}*/}
            <Container>
                <div className="flex jc-center" style={{flexDirection: 'column', alignItems: 'center'}}>
                    {
                        captureVideo
                            ?
                            modelsLoaded
                                ?
                                <div style={{ display: 'flex', justifyContent: 'center', padding: '10px', width: '356px', maxWidth: '100%'}}>
                                    <video
                                        width={320}
                                        height={320}
                                        ref={videoRef}
                                        onPlay={handleVideoOnPlay}
                                        style={name !== 'Вы мне неизвестны' ? {border: '8px solid green'} : {border: '8px solid red'}}
                                    />
                                    <canvas ref={canvasRef} style={{ position: 'absolute' }} />
                                    <canvas width={videoWidth} height={videoHeight} ref={canvasRefImg} style={{ right: '10px', position: 'absolute', maxWidth: '100px', maxHeight: '100px'}} />
                                </div>
                                :
                                <div>Загрузка...</div>
                            :
                            <>
                            </>
                    }
                    <div style={{ textAlign: 'center', padding: '10px', width: '356px', maxWidth: '100%'}}>
                        {
                            captureVideo && modelsLoaded ?
                                <>
                                    <Input value={name} label='Имя' disabled/>
                                    <Button variant={'link'} onClick={() => window.location.reload()}>
                                        Закрыть
                                    </Button>
                                </>
                                :
                                <Button variant={'link'} onClick={startVideo}>
                                    Открыть
                                </Button>
                        }
                    </div>
                </div>
            </Container>
        </div>
    );
});

export default CameraStreamOneComponent;