import React from 'react';
import {Link} from "react-router-dom";
import classes from "./button.module.sass"

const Button = ({variant, icon, children, to, onClick, style}) => {
    switch (variant){
        case 'link':
            return (
                <Link to={to} onClick={onClick} className={classes.button} style={style}>{children}</Link>
            )
        case 'linkText':
            return (
                <Link to={to} onClick={onClick} className={classes.buttonText} style={style}>{children}</Link>
            )
        case 'add':
            return (
                <Link to={to} onClick={onClick} className={classes.add} title={'Добавить'} style={style}><img src={process.env.PUBLIC_URL + '/assets/icons/add.svg'} alt={'Добавить'}/></Link>
            )
        case 'icon':
            return (
                <Link to={to} onClick={onClick} className={classes.icon} title={'Добавить'} style={style}><img style={{marginRight: '16px'}} src={icon} alt={''}/>{children}</Link>
            )
        case 'outline':
            return (
                <Link to={to} onClick={onClick} className={classes.outline} style={style}>{children}</Link>
            )
        default:
            return (
                <button onClick={onClick} style={style}>{children}</button>
            );
    }
};

export default Button;