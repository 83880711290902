import React from 'react';
import classes from "./hr.module.sass";

const Hr = () => {
    return (
        <div className={classes.hr}>

        </div>
    );
};

export default Hr;