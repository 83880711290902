import React from 'react';
import {Context} from "../index";
import Loader from "../ui/loader/loader";
import Cart from "../ui/cart/cart";
import {observer} from "mobx-react-lite";
import JournalService from "../services/journalService";
import Container from "../ui/container/container";
import Moment from "react-moment";
import {REACT_APP_API_URL_STATIC} from "../config/config";

const DashboardComponent = observer(() => {
    const {journal} = React.useContext(Context);
    const [loading, setLoading] = React.useState(true)

    React.useEffect(() => {
        JournalService.getJournals(1)
            .then(data => {
                journal.setJournal(data.rows.at(0))
            })
            .catch(err => console.log(err.response.data.message))
            .finally(() => {
                setLoading(false);
            })
    }, [journal, journal.isJournal]);

    if (loading) {
        return <Loader />
    }

    return (
        <Container>
            <div className="flex jc-center">
                <Cart>
                    <div style={{display: "flex", justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px'}}>
                        <img width={100} height={100} src={REACT_APP_API_URL_STATIC + `/img/${journal.isJournal.company}/${journal.isJournal.img}`} alt={journal.isJournal.img} style={{objectFit: 'cover'}}/>
                        <span>=</span>
                        <img width={100} height={100} src={REACT_APP_API_URL_STATIC + `/img/checked/${journal.isJournal.imgCheck}`} alt={journal.isJournal.imgCheck} style={{objectFit: 'cover'}}/>
                    </div>
                    <div className="name">
                        <span>Имя:</span>
                        <p>{journal.isJournal.name}</p>
                    </div>
                    <div className="name">
                        <span>Движение:</span>
                        <p>{journal.isJournal.movement}</p>
                    </div>
                    <div className="name">
                        <span>Дата:</span>
                        <p><Moment format={'YYYY.MM.DD HH:mm:ss'}>{journal.isJournal.createdAt}</Moment></p>
                    </div>
                </Cart>
            </div>
        </Container>
    );
});

export default DashboardComponent;