import React from 'react';
import LocationOneComponent from "../components/locationOneComponent";
import App from "../App";

const LocationOnePage = () => {
    return (
        <>
            <App/>
            <LocationOneComponent />
        </>
    );
};

export default LocationOnePage;