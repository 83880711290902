import React from 'react';
import {Context} from "../index";
import {useNavigate} from "react-router-dom";
import LoginComponent from "../components/loginComponent";

const LoginPage = () => {
    const {user} = React.useContext(Context);
    const navigate = useNavigate();

    React.useEffect(() => {
        user.isAuth && navigate('/');
    }, [navigate, user.isAuth]);

    return (
        <LoginComponent />
    );
};

export default LoginPage;