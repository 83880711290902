import React from 'react';
import AddComponent from "../components/addComponent";
import App from "../App";

const AddPage = () => {
    return (
        <>
            <App/>
            <AddComponent />
        </>
    );
};

export default AddPage;