import {makeAutoObservable} from "mobx";

export default class CameraStore {
    constructor() {
        this._camera = {}
        this._camerass = []
        makeAutoObservable(this);
    }

    setCamera(camera) {
        this._camera = camera
    }

    setCameras(cameras) {
        this._cameras = cameras
    }

    get isCamera(){
        return this._camera
    }

    get isCameras(){
        return this._cameras
    }
}