import React from 'react';
import classes from "./container.module.sass";

const Container = ({variant, children}) => {
    switch (variant){
        case 'leftBar':
            return (
                <div className={classes.containerLeftBar}>
                    {children}
                </div>
            );
        case 'rightBar':
            return (
                <div className={classes.containerRightBar}>
                    {children}
                </div>
            );
        default:
            return (
                <div className={classes.container}>
                    {children}
                </div>
            );
    }
};

export default Container;