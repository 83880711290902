import {makeAutoObservable} from "mobx";

export default class JournalStore {
    constructor() {
        this._journals = []
        this._journal = {}
        makeAutoObservable(this);
    }

    setJournals(journals) {
        this._journals = journals
    }

    get isJournals(){
        return this._journals
    }

    setJournal(journal) {
        this._journal = journal
    }

    get isJournal(){
        return this._journal
    }
}