import {makeAutoObservable} from "mobx";

export default class CompanyStore {
    constructor() {
        this._company = {}
        this._companyies = []
        makeAutoObservable(this);
    }

    setCompany(company) {
        this._company = company
    }

    setCompanies(companies) {
        this._companyies = companies
    }

    get isCompany(){
        return this._company
    }

    get isCompanies(){
        return this._companyies
    }
}