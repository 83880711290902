import React from 'react';
import CategoryComponent from "../components/categoryComponent";
import App from "../App";

const CategoryPage = () => {
    return (
        <>
            <App/>
            <CategoryComponent/>
        </>
    );
};

export default CategoryPage;