import React from 'react';
import UserOneComponent from "../components/userOneComponent";
import App from "../App";

const UserOnePage = () => {
    return (
        <>
            <App/>
            <UserOneComponent />
        </>
    );
};

export default UserOnePage;