import {$authHost} from "../http/axios";

export default class CompanyService{
    static getCompanies = async () => {
        const {data} = await $authHost.get('/company');
        return data;
    }

    static getCompany = async (id) => {
        const {data} = await $authHost.get(`/company/${id}`);
        return data;
    }

    static addCompany = async (name) => {
        const {data} = await $authHost.post('/company', {name});
        return data;
    }

    static editCompany = async (id, name) => {
        const {data} = await $authHost.put(`/company/${id}`, {name});
        return data;
    }

    static removeCompany = async (id) => {
        const {data} = await $authHost.delete(`/company/${id}`);
        return data;
    }
}