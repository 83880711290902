import React from 'react';
import classes from './popup.module.sass';

const Popup = ({children}) => {
    return (
        <div className={classes.popup}>
            {children}
        </div>
    );
};

export default Popup;