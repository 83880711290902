export const adminMenu = [
    {id: 1, url: '/', title: 'Главная', icon: process.env.PUBLIC_URL + '/assets/icons/home.svg'},
    {id: 2, url: '/users', title: 'Пользователи', icon: process.env.PUBLIC_URL + '/assets/icons/users.svg'},
    {id: 4, url: '/peoples', title: 'Люди', icon: process.env.PUBLIC_URL + '/assets/icons/detected.svg'},
    {id: 5, url: '/journals', title: 'Журнал', icon: process.env.PUBLIC_URL + '/assets/icons/journal.svg'},
    {id: 6, url: '/companies', title: 'Настройки', icon: process.env.PUBLIC_URL + '/assets/icons/setting.svg'},
    {id: 7, url: '/profile', title: 'Профиль', icon: process.env.PUBLIC_URL + '/assets/icons/profile.svg'},
    {id: 8, url: '/add', title: 'Добавить', icon: process.env.PUBLIC_URL + '/assets/icons/add.svg'},
]

export const adminSettingMenu = [
    {id: 1, url: '/companies', title: 'Компании', icon: process.env.PUBLIC_URL + '/assets/icons/company.svg'},
    {id: 2, url: '/locations', title: 'Локации', icon: process.env.PUBLIC_URL + '/assets/icons/locations.svg'},
    {id: 3, url: '/cameras', title: 'Камеры', icon: process.env.PUBLIC_URL + '/assets/icons/camera.svg'},
    {id: 4, url: '/categories', title: 'Категории', icon: process.env.PUBLIC_URL + '/assets/icons/folder.svg'},
    {id: 5, url: '/roles', title: 'Роли', icon: process.env.PUBLIC_URL + '/assets/icons/role.svg'},
]

export const moderatorMenu = [
    {id: 1, url: '/peoples', title: 'Люди', icon: process.env.PUBLIC_URL + '/assets/icons/detected.svg'},
    {id: 2, url: '/journals', title: 'Журнал', icon: process.env.PUBLIC_URL + '/assets/icons/journal.svg'},
    {id: 3, url: '/add', title: 'Добавить', icon: process.env.PUBLIC_URL + '/assets/icons/add.svg'},
    {id: 4, url: '/profile', title: 'Профиль', icon: process.env.PUBLIC_URL + '/assets/icons/profile.svg'},
]

export const managerMenu = [
    {id: 1, url: '/peoples', title: 'Сотрудники', icon: process.env.PUBLIC_URL + '/assets/icons/detected.svg'},
    {id: 2, url: '/add', title: 'Добавить', icon: process.env.PUBLIC_URL + '/assets/icons/add.svg'},
    {id: 3, url: '/profile', title: 'Профиль', icon: process.env.PUBLIC_URL + '/assets/icons/profile.svg'},
]

export const securityMenu = [
    {id: 1, url: '/', title: 'Главная', icon: process.env.PUBLIC_URL + '/assets/icons/home.svg'},
    {id: 2, url: '/peoples', title: 'Люди', icon: process.env.PUBLIC_URL + '/assets/icons/detected.svg'},
    {id: 3, url: '/add', title: 'Добавить', icon: process.env.PUBLIC_URL + '/assets/icons/add.svg'},
    {id: 4, url: '/profile', title: 'Профиль', icon: process.env.PUBLIC_URL + '/assets/icons/profile.svg'},
]