import {makeAutoObservable} from "mobx";

export default class UserStore {
    constructor() {
        this._isAuth = false
        this._role = 1
        this._user = {}
        this._users = []
        makeAutoObservable(this);
    }

    setIsAuth(bool) {
        this._isAuth = bool
    }

    setUser(user) {
        this._user = user
    }

    setUsers(users) {
        this._users = users
    }

    setRole(role) {
        this._role = role
    }

    get isAuth(){
        return this._isAuth
    }

    get isUser(){
        return this._user
    }

    get isUsers(){
        return this._users
    }

    get isRole(){
        return this._role
    }
}