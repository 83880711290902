import React from 'react';
import CameraOneComponent from "../components/cameraOneComponent";
import App from "../App";

const CameraOnePage = () => {
    return (
        <>
            <App />
            <CameraOneComponent />
        </>
    );
};

export default CameraOnePage;