import React from 'react';
import classes from './select.module.sass';

const Select = ({children, onChange, value}) => {
    return (
        <select value={value} className={classes.select} onChange={onChange}>
            <option value={''} disabled>Выберите из списка</option>
            {children}
        </select>
    );
};

export default Select;