import {$authHost} from "../http/axios";

export default class LocationService{
    static getLocations = async () => {
        const {data} = await $authHost.get('/location');
        return data;
    }

    static getLocation = async (id) => {
        const {data} = await $authHost.get(`/location/${id}`);
        return data;
    }

    static addLocation = async (name) => {
        const {data} = await $authHost.post('/location', {name});
        return data;
    }

    static editLocation = async (id, name) => {
        const {data} = await $authHost.put(`/location/${id}`, {name});
        return data;
    }

    static removeLocation = async (id) => {
        const {data} = await $authHost.delete(`/location/${id}`);
        return data;
    }
}