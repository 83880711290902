import React from 'react';
import PeopleComponent from "../components/peopleComponent";
import App from "../App";

const PeoplePage = () => {
    return (
        <>
            <App/>
            <PeopleComponent/>
        </>
    );
};

export default PeoplePage;