import React from 'react';
import JournalComponent from "../components/journalComponent";
import App from "../App";

const JournalPage = () => {
    return (
        <>
            <App/>
            <JournalComponent/>
        </>
    );
};

export default JournalPage;