import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Context} from "../index";
import Loader from "../ui/loader/loader";
import Container from "../ui/container/container";
import Cart from "../ui/cart/cart";
import CompanyService from "../services/companyService";
import BottomMenu from "../ui/menu/bottomMenu";
import Popup from "../ui/popup/popup";
import Hr from "../ui/hr/hr";
import Button from "../ui/button/button";
import Input from "../ui/input/input";
import {observer} from "mobx-react-lite";

const CompanyOneComponent = observer(() => {
    const {id} = useParams();
    const {company} = React.useContext(Context);
    const navigate = useNavigate()
    const [error, setError] = React.useState('');
    const [loader, setLoader] = React.useState(true);
    const [edit, setEdit] = React.useState(false);
    const [popup, setPopup] = React.useState(false);
    const [name, setName] = React.useState('');

    React.useEffect(() => {
        CompanyService.getCompany(id)
            .then(data => company.setCompany(data))
            .catch(err => setError(err.response.data.message))
            .finally(() => setLoader(false))
    }, [id, company])

    const companyEdit = () => {
        setEdit(true);
        setName(company.isCompany?.name);
    }

    const canselEdit = () => {
        setEdit(false);
        setName('');
    }

    const saveEdit = () => {
        setLoader(true);
        CompanyService.editCompany(id, name)
            .then(() => navigate(-1))
            .catch(err => setError(err.response.data.message))
            .finally(() => setLoader(false))
    }

    const openPopup = () => {
        setPopup(true);
    }

    const closePopup = () => {
        setPopup(false);
    }

    const deleteCompany = async () => {
        setLoader(true)
        CompanyService.removeCompany(id)
            .then(() => navigate('/companies'))
            .catch(err => setError(err.response.data.message))
            .finally(() => setLoader(false))
    }

    if (loader){
        return <Loader/>
    }

    return (
        <div>
            {
                error && <p>{error}</p>
            }
            <Container>
                <div className="flex jc-center">
                    {
                        !edit
                            ?
                            <Cart
                                variant={'link'}
                                variantBack={'outline'}
                                type={'add'}
                                buttonText={'Изменить'}
                                buttonBackText={'Назад'}
                                next={companyEdit}
                                back={() => navigate(-1)}
                            >
                                <div className="name">
                                    <span>Имя:</span>
                                    <p>{company.isCompany?.name}</p>
                                </div>
                            </Cart>
                            :
                            <Cart
                                variant={'link'}
                                variantBack={'outline'}
                                variantDel={'linkText'}
                                type={'delete'}
                                buttonText={'Сохранить'}
                                buttonBackText={'Отмена'}
                                buttonDelText={'Удалить'}
                                next={saveEdit}
                                back={canselEdit}
                                del={openPopup}
                            >
                                <div className="name">
                                    <span>Имя:</span>
                                    <Input value={name} onChange={event => setName(event.target.value)}/>
                                </div>
                            </Cart>
                    }
                </div>
            </Container>
            <BottomMenu>
                <div className="count"></div>
                <div className="addButton"></div>
            </BottomMenu>
            {
                popup &&
                <Popup>
                    <div style={{marginTop: '72px', flexDirection: 'column'}} className='flex jc-center ai-center'>
                        <Cart>
                            <h3 style={{marginBottom: '16px'}}>Вы точно хотите удалить камеру: {name}?</h3>
                            <Hr/>
                            <Button onClick={deleteCompany} style={{width: '100%'}} variant={'linkText'}>Да</Button>
                            <Button onClick={closePopup} style={{width: '100%', marginTop: '16px'}}  variant={'link'}>Отмена</Button>
                        </Cart>
                    </div>
                </Popup>
            }
        </div>
    );
});

export default CompanyOneComponent;