import {makeAutoObservable} from "mobx";

export default class PeopleStore {
    constructor() {
        this._people = {}
        this._peoples = []
        makeAutoObservable(this);
    }

    setPeople(people) {
        this._people = people
    }

    setPeoples(peoples) {
        this._peoples = peoples
    }

    get isPeople(){
        return this._people
    }

    get isPeoples(){
        return this._peoples
    }
}