import React from 'react';
import CompanyOneComponent from "../components/companyOneComponent";
import App from "../App";

const CompanyOnePage = () => {
    return (
        <>
            <App/>
            <CompanyOneComponent />
        </>
    );
};

export default CompanyOnePage;