import React from 'react';
import LocationComponent from "../components/locationComponent";
import App from "../App";

const LocationPage = () => {
    return (
        <>
            <App/>
            <LocationComponent />
        </>
    );
};

export default LocationPage;