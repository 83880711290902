import React from 'react';
import classes from "./menu.module.sass";

const BottomMenu = ({children}) => {
    return (
        <div className={classes.bottomMenu}>
            {children}
        </div>
    );
};

export default BottomMenu;