import React from 'react';
import App from "../App";
import DashboardComponent from "../components/dashboardComponent";

const DashboardPage = () => {
    return (
        <>
            <App/>
            <DashboardComponent/>
        </>
    );
};

export default DashboardPage;