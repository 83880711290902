import React from 'react';
import TopMenu from "./ui/menu/topMenu";
import {Context} from "./index";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {adminMenu, managerMenu, moderatorMenu, securityMenu} from "./routes/menu";
import './App.sass';
import UserService from "./services/userService";
import {observer} from "mobx-react-lite";
import Loader from "./ui/loader/loader";


const App = observer(() => {
    const {user} = React.useContext(Context);
    const location = useLocation();
    const navigate = useNavigate();
    const [loader, setLoader] = React.useState(true);

    React.useEffect(() => {
        UserService.check()
            .then(data => {
                user.setIsAuth(true);
                user.setRole(data.roleId);
                if (location.pathname === '/' && user.isRole === 3 || location.pathname === '/' && user.isRole === 2){
                    navigate('/peoples');
                } else if (location.pathname === '/' && user.isRole === 5){
                    navigate('/cameras');
                }
            })
            .catch(() => navigate('/login'))
            .finally(() => setLoader(false))
    }, [navigate, user.isAuth, user, location]);

    const logout = () => {
        localStorage.removeItem('token')
        navigate('/login')
    }

    if (loader){
        return <Loader/>
    }

    return (
        <div>
            {
                user.isAuth &&
                    <TopMenu>
                    <div className="logo">
                        <Link to={'/'}><h1>Checkpoint</h1></Link>
                        <p><span>Alpha - v: 1.00</span></p>
                    </div>
                    <div className="menu">
                        <ul>
                            {
                                user.isRole === 1 &&
                                adminMenu.map(item =>
                                    <li className={item.url === location.pathname && 'active'}>
                                        <Link className={'menuItem'} key={item.id} to={item.url}>
                                            <span className={'icon'}><img src={item.icon} alt={item.title}/></span>
                                            <span className={'title'}>{item.title}</span>
                                        </Link>
                                    </li>
                                )
                            }
                            {
                                user.isRole === 2 &&
                                moderatorMenu.map(item =>
                                    <li className={item.url === location.pathname && 'active'}>
                                        <Link className={'menuItem'} key={item.id} to={item.url}>
                                            <span className={'icon'}><img src={item.icon} alt={item.title}/></span>
                                            <span className={'title'}>{item.title}</span>
                                        </Link>
                                    </li>
                                )
                            }
                            {
                                user.isRole === 3 &&
                                managerMenu.map(item =>
                                    <li className={item.url === location.pathname && 'active'}>
                                        <Link className={'menuItem'} key={item.id} to={item.url}>
                                            <span className={'icon'}><img src={item.icon} alt={item.title}/></span>
                                            <span className={'title'}>{item.title}</span>
                                        </Link>
                                    </li>
                                )
                            }
                            {
                                user.isRole === 4 &&
                                securityMenu.map(item =>
                                    <li className={item.url === location.pathname && 'active'}>
                                        <Link className={'menuItem'} key={item.id} to={item.url}>
                                            <span className={'icon'}><img src={item.icon} alt={item.title}/></span>
                                            <span className={'title'}>{item.title}</span>
                                        </Link>
                                    </li>
                                )
                            }
                            <li>
                                <Link className={'menuItem'} onClick={logout}>
                                    <span className={'icon'}><img src={process.env.PUBLIC_URL + '/assets/icons/logout.svg'} alt={'Выйти'}/></span>
                                    <span className={'title'}>{'Выйти'}</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </TopMenu>
            }
        </div>
    );
});

export default App;