import React from 'react';
import ProfileComponent from "../components/profileComponent";
import App from "../App";

const ProfilePage = () => {
    return (
        <>
            <App/>
            <ProfileComponent />
        </>
    );
};

export default ProfilePage;