import React from 'react';
import CompanyComponent from "../components/companyComponent";
import App from "../App";

const CompanyPage = () => {
    return (
        <>
            <App/>
            <CompanyComponent/>
        </>
    );
};

export default CompanyPage;