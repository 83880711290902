import React from 'react';
import {Context} from "../index";
import UserService from "../services/userService";
import Loader from "../ui/loader/loader";
import Container from "../ui/container/container";
import Cart from "../ui/cart/cart";
import BottomMenu from "../ui/menu/bottomMenu";
import {observer} from "mobx-react-lite";
import Moment from "react-moment";

const UserComponent = observer(() => {
    const {user} = React.useContext(Context);

    const [error, setError] = React.useState('');
    const [loader, setLoader] = React.useState(true);

    React.useEffect(() => {
        UserService.getUsers()
            .then(data => {
                user.setUsers(data);
            })
            .catch(err => {
                setError(err.response.data.message);
            })
            .finally(() => {
                setLoader(false);
            })
    }, [user]);

    if (loader){
        return <Loader/>
    }

    return (
        <div>
            {
                error && <p>{error}</p>
            }
            <Container>
                <div className="flex fw-wrap">
                    {
                        user.isUsers.rows.map(
                            item =>
                                <Cart
                                    key={item.id}
                                    variant={'link'}
                                    type={'button'}
                                    buttonText={'Перейти'}
                                    to={'/users/' + item.id}
                                >
                                    <div className="name">
                                        <span>Имя:</span>
                                        <p>{item.name}</p>
                                    </div>
                                    <div className="email">
                                        <span>Email:</span>
                                        <p>{item.email}</p>
                                    </div>
                                    <div className="phone">
                                        <span>Телефон:</span>
                                        <p>{item.phone}</p>
                                    </div>
                                    <div className="company">
                                        <span>Компания:</span>
                                        <p>{item.company.name}</p>
                                    </div>
                                    <div className="role">
                                        <span>Роль:</span>
                                        <p>{item.role.name}</p>
                                    </div>
                                    <div className="role">
                                        <span>Добавлен(а):</span>
                                        <p><Moment format={'YYYY.MM.DD HH:mm:ss'}>{item.createdAt}</Moment></p>
                                    </div>
                                </Cart>
                        )
                    }
                </div>
            </Container>
            <BottomMenu>
                <div className="count">
                    <span>Всего: </span><p>{user.isUsers.count}</p>
                </div>
                <div className="addButton"></div>
            </BottomMenu>
        </div>
    );
});

export default UserComponent;